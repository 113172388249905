/* import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger); */

let _root = this;

export default class boxVideoBG {
    constructor(root) {
        this.init();
    }

    init() {
        _root = this;
        const _boxVideoBg = document.querySelectorAll('.box-video-bg');

        if ('undefined' != typeof _boxVideoBg && null != _boxVideoBg) {

            _boxVideoBg.forEach(function(item) {
                if ('ontouchstart' in window || navigator.maxTouchPoints) {
                    _root.addEvent(item, 'touchstart', _root.touchstartHandler);
                } else {
                    _root.addEvent(item, 'mouseenter', _root.mouseenterHandler);
                    _root.addEvent(item, 'mouseleave', _root.mouseleaveHandler);
                }
            });
            /*
            if ( !_root.isMobile() ) {

            } else {

                _boxVideoBg.forEach(function(item) {
                    const _video = item.querySelector('video');
                    ScrollTrigger.create({
                        trigger: item,
                        start: 'top-=100px center',
                        end: 'bottom+=100px center',
                        onEnter: () => {
                            gsap.to(item.querySelector('.box-video-bg__vid'), {opacity: 1, duration: 0.5});
                            let _video = item.querySelector('video');
                            _video.currentTime = 0;
                            _video.play();
                        },
                        onLeave: () => {
                            gsap.to(item.querySelector('.box-video-bg__vid'), {opacity: 0, duration: 0.5});
                            let _video = item.querySelector('video');
                            if (!_video.paused) {
                                _video.pause();
                            }
                        }
                    });
                });

            }
            */
        }

    }
    /*
    isMobile() {
        if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            return true
        }
        else {
            return false
        };
    };
    */
    touchstartHandler(e) {
        let _video = e.target.querySelector('video');
        if (_video.paused) {
            _video.play();
        } else {
            _video.pause();
        }
    }

    mouseenterHandler(e) {
        let _video = e.target.querySelector('video');
        _video.currentTime = 0;
        _video.play();
    }

    mouseleaveHandler(e) {
        let _video = e.target.querySelector('video');
        _video.pause();
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}