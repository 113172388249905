import { TextLinesReveal } from './textLinesReveal';
import { gsap } from 'gsap';
require('intersection-observer');

let _root;

export default class TextAppear {
    constructor(root) {
        _root = this;
        this.start();
    }

    start() {
        document.querySelectorAll('.text-holder').forEach(function(_txtHolder) {

            _txtHolder._aTexts = new Array();

            const text_h1 = _txtHolder.querySelector('h1');
            if ('undefined' != typeof text_h1 && null != text_h1) {
                _root.detectElHasChildEm(_txtHolder, text_h1);
            }

            const text_h2 = _txtHolder.querySelector('h2');
            if ('undefined' != typeof text_h2 && null != text_h2) {
                _root.detectElHasChildEm(_txtHolder, text_h2);
            }

            const text_h3 = _txtHolder.querySelector('h3');
            if ('undefined' != typeof text_h3 && null != text_h3) {
                _root.detectElHasChildEm(_txtHolder, text_h3);
            }

            const text_h4 = _txtHolder.querySelector('h4');
            if ('undefined' != typeof text_h4 && null != text_h4) {
                text_h4.style.opacity = 0;
                _txtHolder._aTexts.push(text_h4);
            }

            const text_p = _txtHolder.querySelector('p');
            if ('undefined' != typeof text_p && null != text_p) {
                gsap.set(text_p, { opacity: 0, y: 50 });
            }

            const text_ul = _txtHolder.querySelector('ul');
            if ('undefined' != typeof text_ul && null != text_ul) {
                gsap.set(text_ul, { opacity: 0, y: 50 });
            }

            gsap.set(_txtHolder, { opacity: 0 });

            _txtHolder.textLinesReveal = new TextLinesReveal(_txtHolder._aTexts);



        });
    }

    detectElHasChildEm(txtholder, txt) {
        if (txt.childElementCount !== 0) {

            var res = txt.innerHTML.split("<br>");

            txt.innerHTML = "";

            res.forEach(element => {

                let elType = 'span';

              /*   if (_root.isHTML(element)) {
                    elType = 'em';
                }
 */
                const spanEl = document.createElement(elType);
                const el = txt.appendChild(spanEl);
                el.innerHTML = element;

                txtholder._aTexts.push(el);

            });

        } else {
            txt.style.opacity = 0;
            txtholder._aTexts.push(txt);
        }
    }

    isHTML(str) {
        var doc = new DOMParser().parseFromString(str, "text/html");
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    }

    refresh() {
        this.start();

        setTimeout(function() {
            _root.init();
        }, 400);

    }



    init() {

        document.querySelectorAll('.text-holder').forEach(function(_txtHolder) {

            const observer = new IntersectionObserver(
                function(entries) {
                    if (true === entries[0].isIntersecting) {

                        _txtHolder.textLinesReveal.in();

                        _txtHolder._aTexts.forEach(function(_txt) {
                            _txt.style.opacity = 1;
                        });

                        const text_p = _txtHolder.querySelector('p');
                        if ('undefined' != typeof text_p && null != text_p) {
                            gsap.set(text_p, { opacity: 0, y: 50 });
                            gsap.to(text_p, {
                                duration: 0.5,
                                opacity: 1,
                                y: 0,
                                delay: 0.8
                            });
                        }

                        const text_ul = _txtHolder.querySelector('ul');
                        if ('undefined' != typeof text_ul && null != text_ul) {
                            gsap.set(text_ul, { opacity: 0, y: 50 });
                            gsap.to(text_ul, {
                                duration: 0.5,
                                opacity: 1,
                                y: 0,
                                delay: 0.8
                            });
                        }

                        gsap.set(_txtHolder, { opacity: 1 });

                        observer.unobserve(_txtHolder);

                    } else {
                        _txtHolder.textLinesReveal.out();
                    }
                }, { threshold: [0] }
            );

            observer.observe(_txtHolder);

        });


    }
}