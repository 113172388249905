import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/pagination';

export default class PostsCarousel {
  constructor(root) {
    this.init();
  }

  init() {

    document.querySelectorAll('.posts-carousel-swiper').forEach(_swiperContainer => {

      let _columnsMax = 3;

      const swiper = new Swiper(_swiperContainer, {
        speed: 300,
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoHeight: true,
        init: false,
        modules: [Pagination],
        pagination: {
          el: _swiperContainer.parentElement.querySelector('.swiper-pagination'),
          clickable: true
        },
        breakpoints: {
          '500': {
            slidesPerView: 1,
            spaceBetween: 20
          },
          '700': {
            slidesPerView: _columnsMax - 1,
            spaceBetween: 30
          },
          '1024': {
            slidesPerView: _columnsMax,
            spaceBetween: 40
          },
          '1400': {
            slidesPerView: _columnsMax,
            spaceBetween: 50
          }
        }

      });
      if ('undefined' != typeof _swiperContainer && null != _swiperContainer) {
        swiper.init();
      }
    });
  }
}
