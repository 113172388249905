import { gsap, Power4 } from 'gsap';

let _root;
let _tl;
let _overlaySearch;
let _overlaySearchBg;
let _overlaySearchClose;
let _overlaySearchContent;
let _searchBtnToggle;
let _searchIsVisible = false;

export default class SearchOverlay {
    constructor(root) {
        _root = this;
        _overlaySearch = document.querySelector('#overlay-search');
        if ('undefined' != typeof _overlaySearch && null != _overlaySearch) {
            _overlaySearchClose = _overlaySearch.querySelector('.overlay-search__close');
            _overlaySearchBg = _overlaySearch.querySelector('.overlay-search__bg');
            _overlaySearchContent = _overlaySearch.querySelector('.overlay-search__content');
            _searchBtnToggle = document.querySelector('#search-button');
        }
        if ('undefined' != typeof _searchBtnToggle && null != _searchBtnToggle) {
            this.initButton();
        }
    }

    resetSearchOverlay() {
        gsap.set(_overlaySearchBg, { display: 'none', width: 0 });
        gsap.set(_overlaySearchContent, { display: 'none', autoAlpha: 1 });
        gsap.set(_overlaySearchClose, { display: 'none', opacity: 1 });
        gsap.set(_overlaySearch, { display: 'none' });
    }

    prepareSearchOverlay() {
        gsap.set(_overlaySearch, { display: 'block' });
        gsap.set(_overlaySearchBg, { display: 'block', width: 0, transformOrigin: 'top right' });
        gsap.set(_overlaySearchContent, { display: 'flex', autoAlpha: 0 });
        gsap.set(_overlaySearchClose, { display: 'block', autoAlpha: 0 });
    }

    initButton() {
        _root.addEvent(_searchBtnToggle, 'click', _root.onClickOverlayMenu);
    }

    onClickOverlayMenu(el) {
        el.preventDefault();

        _tl = gsap.timeline({ onComplete: _root.onCompleteTimelineLite, onReverseComplete: _root.reverseFunction });

        _root.unloadScrollBars();

        _root.prepareSearchOverlay();

        _tl.to(_overlaySearchBg, 0.5, { width: '100%', transformOrigin: 'top right', ease: Power4.easeInOut });

        _tl.to(_overlaySearchContent, 0.1, { autoAlpha: 1 });

        _tl.to(_overlaySearchClose, 0.3, { autoAlpha: 1 });

        _root.removeEvent(_searchBtnToggle, 'click', _root.onClickOverlayMenu);
    }

    reloadScrollBars() {
        document.documentElement.style.overflowY = 'auto';
        document.body.scroll = 'yes';
    }

    unloadScrollBars() {
        document.documentElement.style.overflowY = 'hidden';
        document.body.scroll = 'no';
    }

    onCompleteTimelineLite() {
        _root.addEvent(_overlaySearchClose, 'click', _root.onClickCloseEvent);

        _searchIsVisible = true;

    }

    reverseFunction() {
        _root.reloadScrollBars();
        _root.resetSearchOverlay()
        _root.initButton();
        _searchIsVisible = false;
    }

    onClickCloseEvent(el) {
        el.preventDefault();
        _tl.reverse();
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }
}