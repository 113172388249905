import { gsap, Power4 } from 'gsap';

let _root = this;
let _headerCurrentHeight;
let _header;
let _headerBg;

export default class initNavigation {
    constructor(root) {
       this.init();
    }

    init() {
        _root = this;
        _header = document.querySelector('#header');
        _root.initMenu();
    }

    initMenu() {

        const _primaryMenu = document.querySelector('#main-navigation');
        let element = _primaryMenu.querySelectorAll('#main-navigation > .menu-item-has-children');

        if ('undefined' != typeof element && null != element) {
            element.forEach(function(item) {

                let dropdownMenu = item.querySelector('.dropdown-menu');
                dropdownMenu.tl = gsap.timeline({ paused: true });

                dropdownMenu.tl.from(dropdownMenu.querySelectorAll('.menu-item'), {
                    duration: 0.3,
                    x: -30,
                    opacity: 0,
                    ease: Power4.easeInOut,
                    delay: 0.1,
                    stagger: {
                        amount: 0.2
                    }

                });

                _root.addEvent(item, 'mouseenter', _root.menuItemMouseOver);
                _root.addEvent(item, 'mouseleave', _root.menuItemMouseOut);
            });
        }

        _primaryMenu.querySelectorAll('#main-navigation .menu-item').forEach(function(item) {
            _root.addEvent(item.querySelector('a'), 'click', _root.onClickMenuItem);
        });
    }

    onClickMenuItem(e) {
        document.querySelectorAll('#main-navigation .menu-item').forEach(function(item) {
            item.classList.remove('current-menu-item');
        });
        e.target.parentElement.classList.add('current-menu-item');

        // e.target.classList.contains('activated') ? e.target.classList.remove('activated') : e.target.classList.add('activated');
        // const _header = document.querySelector('#header');
        // _headerCurrentHeight = _header.offsetHeight;
        // _header.classList.contains('open') ? _header.classList.remove('open') : _header.classList.add('open');

        // gsap.set(_headerBg, { opacity: 0, height: 0 });

        // gsap.set(e.target.parentElement.parentElement.parentElement.parentElement.querySelectorAll('.menu-item'), { x: -30, opacity: 0 });

    }

    showBgHeader() {
        const header_bg = document.createElement("div");
        header_bg.classList.add('header-bg');
        _header.append(header_bg);
        _headerBg = _header.querySelector('.header-bg');
        gsap.set( _headerBg, { display: "block" } );

    }

    removeBgHeader() {
        if ('undefined' != typeof _headerBg && null != _headerBg) {
            _headerBg.remove();
        }
    }

    menuItemMouseOver(e) {

        e.target.classList.contains('activated') ? e.target.classList.remove('activated') : e.target.classList.add('activated');

        const _header = document.querySelector('#header');
        _headerCurrentHeight = _header.offsetHeight;
        let dropdownMenu = e.target.querySelector('.dropdown-menu');
        //let _hh = dropdownMenu.offsetHeight - _headerCurrentHeight;
        let _hh = dropdownMenu.offsetHeight;

        _header.classList.contains('open') ? _header.classList.remove('open') : _header.classList.add('open');

        //megamenu-prodotti

        if ( e.target.classList.contains('megamenu-prodotti') ) {
            _root.showBgHeader();
        }

        _root.tweenHeightFunction(_headerBg, _hh, 0.3, Power4.easeOut);

        dropdownMenu.tl.play();
    }

    menuItemMouseOut(e) {

        e.target.classList.contains('activated') ? e.target.classList.remove('activated') : e.target.classList.add('activated');

        let dropdownMenu = e.target.querySelector('.dropdown-menu');
        dropdownMenu.tl.reverse();

        const _header = document.querySelector('#header');

        _header.classList.contains('open') ? _header.classList.remove('open') : _header.classList.add('open');

        _root.tweenHeightFunction(_headerBg, 0, 0.3, Power4.easeIn);
        _root.removeBgHeader();
    }

    tweenHeightFunction(_trg, _hh, _speed, _ease) {

        gsap.to(_trg, {
            duration: _speed,
            height: _hh,
            ease: _ease,
            onComplete: () => {
             /*    if ( _direction == "out" ) {
                    gsap.set( _trg, { display: "none" } );
                } */
            }
        });
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}