import Carousel from '../components/carousel';
import SliderGallery from '../components/slider-gallery'
import ToggleReadmore from '../components/toggle-readmore';

require('fslightbox');

export default class Oem {
    constructor(root) {
       this.init();
    }

    init() {
        new SliderGallery();
        new ToggleReadmore();
        new Carousel();
    }

}