import { gsap, Power4 } from 'gsap';
import * as ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

let _root = this;
let _header;
let _content;
let _stickyValue = 20;

export default class initHeader {
    constructor(root) {
        this.init();
    }

    init() {
        _root = this;
        _header = document.querySelector('#header');
        _content = document.querySelector('#content');
       
        window.addEventListener('scroll', function(e) {
            _root.headerHeight();
        });

        _root.headerHeight();
    }

    headerHeight() {
        const _toTop = document.querySelector('#totop');
        const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

        if ('undefined' != typeof _header && null != _header) {
            _stickyValue < scrollTop ? _header.classList.add('active') : _header.classList.remove('active');
        }

        if ('undefined' != typeof _content && null != _content) {
            _stickyValue < scrollTop ? _content.classList.add('active') : _content.classList.remove('active');
        }

        if ('undefined' != typeof _toTop && null != _toTop) {
            _stickyValue < scrollTop ? _toTop.classList.add('active') : _toTop.classList.remove('active');
        }
    }

    toTopFunction() {
        gsap.to(window, {
            duration: 1,
            scrollTo: { y: 0 },
            ease: Power4.easeInOut
        });
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}