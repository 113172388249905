import boxVideoBG from '../components/box-video-bg'
import PostsCarousel from '../components/posts-carousel';
import Spotlight from '../fx/Spotlight';

export default class Home {
    constructor(root) {
       this.init();
    }

    init() {
        new boxVideoBG();
        new PostsCarousel();
        new Spotlight({
            toggleEl: "#hero-spotlight",
            innerRadius: 1,
            outerRadius: 800,
            outerColor:"rgba(0,0,0,0.8)",
        });
    }

}