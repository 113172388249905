import { gsap } from "gsap";
import textAppear from './globals/text-appear';

let _isStarted = true;
let _countLoadedImages = 0;
let _allImages = 0;
let imagesLoaded = require('imagesLoaded');
let _preloader;
let _preloaderLogo;
let _preloaderProgress;
let _tlProgress;
let _root;
let _textAppear;
let _tweenInc;
let _portion = 0;

export default class Preloader {
    constructor() {
        _root = this;
        _textAppear = new textAppear();
        this.init();
    }

    init() {
        _preloader = document.getElementById('preloader');
        _preloaderLogo = _preloader.querySelector('.preloader-logo');
        _preloaderProgress = _preloader.querySelector('.preloader-progress');


        if ( GlobalApp.CONTENT_TYPE === "page" && GlobalApp.PAGE_NAME === "home") {
            gsap.set( _preloader, { display: 'flex' } );

            let _imagesLoaded = imagesLoaded(document.querySelectorAll('.img-preloader'), { background: 'div' });
            _allImages = _imagesLoaded.images.length;
            _imagesLoaded.on('always', _root.onAlways);
            _imagesLoaded.on('progress', _root.onProgress);
        } else {
            _textAppear.init();
        }

    }

    animationStart() {
        _tlProgress = new gsap.timeline({ delay: 1, onStart: _root.onInitStart, onComplete: _root.onInitComplete });

        _tlProgress.set(_preloader, { display: 'block', autoAlpha: 1 });
        _tlProgress.to(_preloader, 0.3, { autoAlpha: 0 });

        _tlProgress.set(_preloaderLogo, { display: 'block', autoAlpha: 1 });
        _tlProgress.to(_preloaderLogo, 0.3, { autoAlpha: 0 });

        _tlProgress.set(_preloaderProgress, { display: 'block', autoAlpha: 1 });
        _tlProgress.to(_preloaderProgress, 0.3, { autoAlpha: 0 });
    }

    onInitComplete() {
        if (_isStarted) {
            _isStarted = false;
        }

        //
    }

    onInitStart() {

        document.querySelectorAll('.text-holder').forEach(function(_txtHolder) {
            gsap.set(document.querySelector('.elementor-heading-title'), { opacity: 1 });
        });

        _textAppear.init();
    }

    onAlways(instance) {
        _root.animationStart();
    }
    
    progressUpdate() { 
        let _width = Math.round(_tweenInc.progress() * _portion);
        if ('undefined' != typeof _preloaderProgress && null != _preloaderProgress) {
            _preloaderProgress.textContent = _width + '%';
        }
    }

    onProgress(instance, image) {

        _portion = Math.round( 100 - (100 / _countLoadedImages) );
        if (_portion >= 95) {
            _portion = 100;
        }

        if (image.isLoaded) {
            _countLoadedImages++;
        }

        _tweenInc = gsap.to(_preloader.querySelector('.preloader-bar-inner'), {
            duration: 0.5,
            width: _portion+"%",
            onUpdate: _root.progressUpdate,
            ease: gsap.Linear
        });
    }

}
