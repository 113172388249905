import { gsap, Power4 } from 'gsap';

let _root = this;
let _heroVideoBg;

export default class HeroVideoBG {
    constructor(root) {
        this.init();
    }

    init() {
        _root = this;
        _heroVideoBg = document.querySelector('.hero-video-bg');

        if ('undefined' != typeof _heroVideoBg && null != _heroVideoBg) {
            window.addEventListener('scroll', function(e) {
                _root.onScrollHandler();
            });
        }
    }

    onScrollHandler() {

        const _hideValue = window.innerHeight * 0.5;
        const _scrollPos = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        const _video = _heroVideoBg.querySelector('video');

        if ('undefined' != typeof _video && null != _video) {
            if (_scrollPos > _hideValue) {
            _video.pause();
                gsap.to(_video, { duration: 2, opacity: 0, ease: Power4 });
            } else {
            _video.play();
            gsap.to(_video, { duration: 2, opacity: 1, ease: Power4 });
            }
        }
    }

    touchstartHandler(e) {
        let _video = e.target.querySelector('video');
        if (_video.paused) {
            _video.play();
        } else {
            _video.pause();
        }
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}