import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';

export default class Carousel {
  constructor(root) {
    this.init();
  }

  init() {

    document.querySelectorAll('.carousel-swiper').forEach(_swiperContainer => {

      let _columnsMax = 4;

      if (_swiperContainer.dataset.columns === '3') {
        _columnsMax = 3;
      }

      const swiper = new Swiper(_swiperContainer, {
        
         //loop: true,
        speed: 300,
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoHeight: true, //enable auto height
        init: false,
        modules: [Navigation],
        navigation: {
          nextEl: _swiperContainer.parentElement.querySelector(".swiper-button-next"),
          prevEl: _swiperContainer.parentElement.querySelector(".swiper-button-prev"),
        },
        breakpoints: {
          '500': {
            slidesPerView: _columnsMax - 2,
            spaceBetween: 20
          },
          '700': {
            slidesPerView: _columnsMax - 1,
            spaceBetween: 30
          },
          '1024': {
            slidesPerView: _columnsMax,
            spaceBetween: 40
          },
          '1400': {
            slidesPerView: _columnsMax,
            spaceBetween: 50
          }
        }

      });
      if ('undefined' != typeof _swiperContainer && null != _swiperContainer) {
        swiper.init();
      }
    });
  }
}
