//import { gsap, Power2 } from "gsap";
var Spotlight = /** @class */ (function () {
    function Spotlight(_a) {
        var toggleEl = _a.toggleEl, _b = _a.outerColor, outerColor = _b === void 0 ? "#000000ee" : _b, _c = _a.innerRadius, innerRadius = _c === void 0 ? 10 : _c, _d = _a.outerRadius, outerRadius = _d === void 0 ? 350 : _d;
        this.active = true;
        this.el = this.insertSpotlightElement();
        this.toggleEl = toggleEl;
        this.outerColor = outerColor;
        this.innerRadius = innerRadius;
        this.outerRadius = outerRadius;
        var _root = this;
        /*  const myEl:HTMLElement = this.el;
         
         gsap.set(myEl, { y:window.innerHeight });
  */
        var posX = window.innerWidth * 0.5;
        var posY = window.innerHeight * 0.5;
        _root.updateEl(posX, posY);
        /*
        gsap.to(myEl, 5, {
            x: window.innerWidth,
            y: 0,
            repeat: -1,
            yoyo: true,
            ease: Power2.easeInOut,
            delay: 1,
            onUpdate:function() {

            let posX:number = Number(gsap.getProperty(this.targets()[0], "x"));
            let posY:number = Number(gsap.getProperty(this.targets()[0], "y"));
            _root.updateEl(posX, posY);
        } });
        */
        this.active = true;
        this.el.style.animation = "enter 1s ease forwards";
        this.boundEventListener = this.handleMouseMove.bind(this);
        document.addEventListener("mousemove", this.boundEventListener);
    }
    /*   switchOn() {
        this.active = true;
    
        document.addEventListener("mousemove", this.boundEventListener);
    
        this.el.style.animation = "enter 1s ease forwards";
    
        setTimeout(() => {
          this.el.style.animation =
            "pulse 3s ease-in-out infinite alternate forwards";
        }, 1000);
      } */
    Spotlight.prototype.handleMouseMove = function (event) {
        var _this = this;
        setTimeout(function () {
            _this.updateEl(event.clientX, event.clientY);
        }, 90);
    };
    Spotlight.prototype.insertSpotlightElement = function () {
        var toggleElSelector = document.querySelector("#hero-spotlight");
        var el = document.createElement("div");
        el.classList.add("spotlight");
        toggleElSelector.appendChild(el);
        return el;
    };
    Spotlight.prototype.updateEl = function (x, y) {
        this.el.style.background = "radial-gradient(circle at ".concat(x, "px ").concat(y, "px, #00000000 ").concat(this.innerRadius, "px, ").concat(this.outerColor, " ").concat(this.outerRadius, "px)");
    };
    return Spotlight;
}());
export default Spotlight;
