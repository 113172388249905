import { gsap, Power4 } from 'gsap';

let _root;
let _tl;
let _overlayMenu;
let _overlayMenuBg;
let _overlayMenuLogo;
let _overlayMenuClose;
let _overlayMenuContent;
let _overlayMenuBgImg;
let _btnMenuToggle;
let _menuIsVisible = false;

export default class MenuOverlay {
    constructor(root) {
        _root = this;
        _overlayMenu = document.querySelector('#overlay-menu');
        if ('undefined' != typeof _overlayMenu && null != _overlayMenu) {
            _overlayMenuLogo = _overlayMenu.querySelector('.overlay-menu__logo');
            _overlayMenuBg = _overlayMenu.querySelector('.overlay-menu__bg');
            _overlayMenuClose = _overlayMenu.querySelector('.overlay-menu__close');
            _overlayMenuContent = _overlayMenu.querySelector('.overlay-menu__content');
            _overlayMenuBgImg = _overlayMenuBg.querySelector('.overlay-menu__bg--img');
            _btnMenuToggle = document.querySelector('#overlay-menu-toggle');
        }
        if ('undefined' != typeof _btnMenuToggle && null != _btnMenuToggle) {
            this.initButton();
        }
        window.addEventListener('resize', () => this.onResize());
    }

    onResize() {
        if (window.innerWidth > 992 && _menuIsVisible) {
            _root.resetOverlayMenu();
            _root.reverseFunction();
        }
    }

    resetOverlayMenu() {
        gsap.set(_overlayMenuContent.querySelectorAll('.menu-item'), { display: 'none', opacity: 1, y: 0 });
        gsap.set(_overlayMenuBg, { display: 'none', width: 0 });
        gsap.set(_overlayMenuContent, { display: 'none', autoAlpha: 1 });
        gsap.set(_overlayMenuLogo, { display: 'none', autoAlpha: 1 });
        gsap.set(_overlayMenuClose, { display: 'none', opacity: 1 });
        gsap.set(_overlayMenuBgImg, { display: 'none', opacity: 0.5, scale: 1 });
        gsap.set(_overlayMenu, { display: 'none' });
    }

    prepareOverlayMenu() {
        gsap.set(_overlayMenu, { display: 'block' });
        gsap.set(_overlayMenuBg, { display: 'block', width: 0, transformOrigin: 'top right' });
        gsap.set(_overlayMenuContent, { display: 'flex', autoAlpha: 0 });
        gsap.set(_overlayMenuLogo, { display: 'block', autoAlpha: 0 });
        gsap.set(_overlayMenuClose, { display: 'block', autoAlpha: 0 });
        gsap.set(_overlayMenuBgImg, { display: 'block', opacity: 0, scale: 1.5 });
        gsap.set(_overlayMenuContent.querySelectorAll('.menu-item'), { display: 'block', opacity: 0, y: 150 });
    }

    initButton() {
        _root.addEvent(_btnMenuToggle, 'click', _root.onClickOverlayMenu);
    }

    onClickOverlayMenu(el) {
        el.preventDefault();

        _tl = gsap.timeline({ onComplete: _root.onCompleteTimelineLite, onReverseComplete: _root.reverseFunction });

        _root.unloadScrollBars();

        _root.prepareOverlayMenu();

        _tl.to(_overlayMenuBg, 0.5, { width: '100%', transformOrigin: 'top right', ease: Power4.easeInOut });

       
        _tl.to(_overlayMenuContent, 0.1, { autoAlpha: 1 });
        _tl.to(_overlayMenuContent.querySelectorAll('.menu-item'), {
            duration: 0.4,
            y: 0,
            opacity: 1,
            ease: Power4.easeInOut,
            delay: 0.1,
            stagger: {
                amount: 0.2
            }
        });
        _tl.to(_overlayMenuLogo, 0.3, { autoAlpha: 1 });
        _tl.to(_overlayMenuClose, 0.3, { autoAlpha: 1 });

        _tl.to(_overlayMenuBgImg, 1, { opacity: 0.5, scale: 1 }, '-=1');

        _root.removeEvent(_btnMenuToggle, 'click', _root.onClickOverlayMenu);
    }

    reloadScrollBars() {
        document.documentElement.style.overflowY = 'auto';
        document.body.scroll = 'yes';
    }

    unloadScrollBars() {
        document.documentElement.style.overflowY = 'hidden';
        document.body.scroll = 'no';
    }

    onCompleteTimelineLite() {
        _root.addEvent(_overlayMenuClose, 'click', _root.onClickCloseEvent);

        _menuIsVisible = true;

        document.querySelectorAll('#overlay-menu a').forEach(_item => {
            _root.addEvent(_item, 'click', _root.onClickMenuItem);
        });
    }

    onClickMenuItem(el) {
        _tl.reverse();
    }

    reverseFunction() {
        _root.reloadScrollBars();
        _root.resetOverlayMenu()
        _root.initButton();
        _menuIsVisible = false;
    }

    onClickCloseEvent(el) {
        el.preventDefault();
        _tl.reverse();
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }
}