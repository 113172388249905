import { gsap, Power4 } from 'gsap';
import * as ScrollToPlugin from "gsap/ScrollToPlugin";

let _root = this;
let circumference = 0;
let path = document.querySelector('#svgPIldCircle');

export default class ToTop {
  constructor(root) {
    _root = this;
    this.init();
  }

  init() {

    window.addEventListener('resize', function (e) {
      _root.getCircumference();
    });

    const _totop = document.querySelector('#totop');

    if ('undefined' != typeof _totop && null != _totop) {
      _root.addEvent(_totop, 'click', _root.onClickToTopEvent);
      _root.addEvent(_totop, 'mouseenter', _root.toTopMouseOver);
      _root.addEvent(_totop, 'mouseleave', _root.toTopMouseOut);
    }

    _root.getCircumference();

  }

  getCircumference() {
    const radius = path.getBoundingClientRect().width / 2;
    circumference = Math.ceil(radius * 2 * Math.PI);
    path.style.strokeDasharray = circumference;
    path.style.strokeDashoffset =  circumference;
  }

  toTopMouseOver() {
    _root.drawCircle(0);
  }

  toTopMouseOut() {
    _root.drawCircle(circumference);
  }

  onClickToTopEvent() {
    gsap.to(window, {
      duration: 2,
      scrollTo: { y: 0 },
      ease: Power4.easeInOut
    });
  }

  drawCircle(length) {
    gsap.to(path, {
      duration: 0.2,
      strokeDashoffset: length
    });
  }

  addEvent(el, type, handler) {
    if (el.attachEvent) el.attachEvent('on' + type, handler);
    else el.addEventListener(type, handler);
  }

  removeEvent(el, type, handler) {
    if (el.detachEvent) el.detachEvent('on' + type, handler);
    else el.removeEventListener(type, handler);
  }

}
