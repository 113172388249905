import { gsap, Power4, TimelineLite } from 'gsap';
import * as ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

let _root = this;

export default class ToggleReadmore {
  constructor(root) {
    _root = this;
    this.init();
  }

  init() {
    const element = document.querySelectorAll('.toggle-readmore-btn');

    if ('undefined' != typeof element && null != element) {
      element.forEach(function(item) {
        _root.addEvent(item, 'click', _root.onCkickEvent);
      });
    }
  }

  onCkickEvent(e) {
    e.preventDefault();
    var _this = e.target;
    var _next = _this.parentElement.querySelector('.toggle-readmore-txt');

    if (!_this.classList.contains('active')) {
      _this.tl = new TimelineLite({ onComplete: _root.onCompleteTimelineLite, onCompleteParams: [_this] });

      _this.tl.set(_next, { display: 'block', height: 0 });
      _this.tl.to(_next, 0.5, { height: 'auto' });
      _this.innerHTML = _this.dataset.deactive;

      _this.classList.add('active');
    } else {
      _this.tl.reverse();
      _this.classList.remove('active');
      _this.innerHTML = _this.dataset.active;
    }
  }

  onCompleteTimelineLite(trg) {
    var scrollTop = trg.parentElement.querySelector('.toggle-readmore-txt').getBoundingClientRect().top + window.scrollY - 50;
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: scrollTop },
      ease: Power4.easeInOut
    });
  }

  addEvent(el, type, handler) {
    if (el.attachEvent) el.attachEvent('on' + type, handler);
    else el.addEventListener(type, handler);
  }

  removeEvent(el, type, handler) {
    if (el.detachEvent) el.detachEvent('on' + type, handler);
    else el.removeEventListener(type, handler);
  }
}
