import Carousel from '../components/carousel';
//import HeroVideoBG from '../components/hero-video-bg';
import SliderGallery from '../components/slider-gallery'
import ToggleReadmore from '../components/toggle-readmore';
import Spotlight from '../fx/Spotlight';

require('fslightbox');

export default class Product {
    constructor(root) {
       this.init();
    }

    init() {
        new SliderGallery();
        new ToggleReadmore();
        new Carousel();
        //new HeroVideoBG();

        new Spotlight({
            toggleEl: "#hero-spotlight",
            innerRadius: 1,
            outerRadius: 800,
            outerColor:"rgba(0,0,0,0.8)",
        });
    }

}