import Header from './globals/header'
import Navigation from './globals/navigation';
import NavigationMobile from './globals/navigation-mobile';
import MegaMenuOEM from './globals/mega-menu-oem';
import MegaMenuSolution from './globals/mega-menu-solutions';
import MenuOverlay from './globals/menu-overlay';
import ProgressBar from './globals/progress-bar';
import Parallax from './globals/parallax';
import ToTop from './globals/totop';
import HeroVideoBG from './hero-video-bg';
import SearchOverlay from './globals/search-overlay';

export default class Globals {
    constructor(root) {
        this.init();
    }

    init() {
        new Header();
        new Navigation();
        new NavigationMobile();
        new MegaMenuOEM();
        new MegaMenuSolution();
        new MenuOverlay();
        new ProgressBar();
        new Parallax();
        new ToTop();
        new HeroVideoBG();
        new SearchOverlay();
    }

}