import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let _root = this;

export default class Parallax {
    constructor() {
        _root = this;
        this.refresh();
    }

    refresh() {
        this.setSize();
    }

    setSize() {
        this.startMoveElements();
        this.startMoveBgParallax();
      


        //this.startBgColor();

      /*   let element = document.querySelectorAll('.parallax-item');

        if ('undefined' != typeof element && null != element) {


            gsap.utils.toArray(".parallax-item").forEach(layer => {

                let speed = 1;
                let direction = 'vertical';

                if (layer.dataset.speed) {
                    speed = layer.dataset.speed;
                }

                if (layer.dataset.direction) {
                    direction = layer.dataset.direction;
                }

                if (direction == "vertical") {
                    let movement = -(layer.offsetHeight * speed);
                    gsap.set( layer, { y: -movement } );
                }

                if (direction == "horizontal") {
                    let movement = -(layer.offsetWidth * speed);
                    gsap.set( layer, { x: -movement } );
                }

            })
        }

        this.startMoveElements();
 */
/* 
        ScrollTrigger.matchMedia({
	
            "(min-width: 991px)": function() {
                ScrollTrigger.create({
                    trigger: ".sticky",
                    start: "top 50%",
                    scrub: true,
                    pin: true,
                    pinSpacing: false
                });
            },
            
        });  */
    }


    startMoveElements() {
/* 
            gsap.utils.toArray(".parallax-image-scale").forEach(item => {

                gsap.set( item, { scale: 1.2 } );

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        scrub: true
                    }
                });

                tl.to(
                    item, {
                        scale: 1,
                        transformOrigin: "center center",
                        ease: "none"
                    }, 0);


            });

        


        let element = document.querySelectorAll('.parallax-item');

        if ('undefined' != typeof element && null != element) {

            gsap.utils.toArray(".parallax-item").forEach(layer => {

                if (layer.dataset.scroll != null) {

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: layer.parentElement,
                            scrub: true
                        }
                    });

                    let speed = 1;
                    let direction = 'vertical';
                    let movement = -(layer.offsetHeight * speed);

                    if (layer.dataset.speed) {
                        speed = layer.dataset.speed;
                    }

                    if (layer.dataset.direction) {
                        direction = layer.dataset.direction;
                    }

                    if (direction == "vertical") {
                        movement = -(layer.offsetHeight * speed);
                        tl.to( layer, { y: movement, ease: "none" }, 0 );
                    }

                    if (direction == "horizontal") {
                        movement = -(layer.offsetWidth * speed);
                        tl.to( layer, { x: movement, ease: "none" }, 0 );
                    }

                    if (direction == "rotate") {
                        tl.to(
                            layer, {
                                rotation: speed,
                                transformOrigin: "center center",
                                ease: "none"
                            }, 0);

                    }

                    if (direction == "scale") {
                        tl.to(
                            layer, {
                                scale: speed,
                                transformOrigin: "center center",
                                ease: "none"
                            }, 0);

                    }

                }

            });

        }
         */
        let element = document.querySelectorAll('.elementor-parallax-item');

        if ('undefined' != typeof element && null != element) {

            gsap.utils.toArray(".elementor-parallax-item").forEach(layer => {

                const _img = layer.querySelector('img');

                let speed = -0.3;
                gsap.set( _img, { y: (layer.offsetHeight * speed) * 0.5 } );

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: layer,
                        scrub: true
                    }
                });
                
                let movement = -(layer.offsetHeight * speed);
                movement = -(layer.offsetHeight * speed);
                tl.to( _img, { y: movement, ease: "none" }, 0 );

            });

        }

    }

    startMoveBgParallax() {

        let element = document.querySelectorAll('.parallax-img__container');

        if ('undefined' != typeof element && null != element) {

            gsap.utils.toArray(".parallax-img__container .parallax-img__img").forEach(section => {
                const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: section.parentElement,
                        scrub: true
                    }
                });

                tl.to(
                    section, {
                        y: -heightDiff,
                        ease: "none"
                    }, 0);
            });
        }
    }
 
}