import { gsap, Power4 } from 'gsap';

let _root = this;
let _headerCurrentHeight;
let _header;
let _headerBg;
let _megaMenuSolutions;

export default class initMegaMenuSolutions {
    constructor(root) {
       this.init();
    }

    init() {
        _root = this;
        _header = document.querySelector('#header');
        const _mainNavigation = document.querySelector('#main-navigation');
        _megaMenuSolutions = _mainNavigation.querySelector('.megamenu-solutions');

        if ('undefined' != typeof _megaMenuSolutions && null != _megaMenuSolutions) {
        
            let megaMenu = _megaMenuSolutions.querySelector('.dropdown-mega-menu-solutions');
            megaMenu.tl = gsap.timeline({ paused: true });

            megaMenu.tl.from(megaMenu.querySelectorAll('.mega-menu-item'), {
                    duration: 0.3,
                    y: 50,
                    opacity: 0,
                    ease: Power4.easeInOut,
                    delay: 0.1,
                    stagger: {
                        amount: 0.2
                    }

                });

            _root.addEvent(_megaMenuSolutions, 'mouseenter', _root.menuItemMouseOver);
            _root.addEvent(_megaMenuSolutions, 'mouseleave', _root.menuItemMouseOut);
        }
    }

    showBgHeader() {
        const header_bg = document.createElement("div");
        header_bg.classList.add('header-bg');
        _header.append(header_bg);
        _headerBg = _header.querySelector('.header-bg');
        gsap.set( _headerBg, { display: "block" } );

    }

    removeBgHeader() {
        if ('undefined' != typeof _headerBg && null != _headerBg) {
            _headerBg.remove();
        }
    }

    menuItemMouseOver(e) {

        e.target.classList.contains('activated') ? e.target.classList.remove('activated') : e.target.classList.add('activated');

        const _header = document.querySelector('#header');
        _headerCurrentHeight = _header.offsetHeight;
        let dropdownMenu = e.target.querySelector('.dropdown-mega-menu-solutions');
        //let _hh = dropdownMenu.offsetHeight - _headerCurrentHeight;
        let _hh = dropdownMenu.offsetHeight;

        _header.classList.contains('open') ? _header.classList.remove('open') : _header.classList.add('open');

         _root.showBgHeader();

        _root.tweenHeightFunction(_headerBg, _hh, 0.3, Power4.easeOut);

        dropdownMenu.tl.play();
    }

    menuItemMouseOut(e) {

        e.target.classList.contains('activated') ? e.target.classList.remove('activated') : e.target.classList.add('activated');

        let dropdownMenu = e.target.querySelector('.dropdown-mega-menu-solutions');
        dropdownMenu.tl.reverse();

        const _header = document.querySelector('#header');

        _header.classList.contains('open') ? _header.classList.remove('open') : _header.classList.add('open');

        _root.tweenHeightFunction(_headerBg, 0, 0.3, Power4.easeIn);
        _root.removeBgHeader();
    }

    tweenHeightFunction(_trg, _hh, _speed, _ease) {

        gsap.to(_trg, {
            duration: _speed,
            height: _hh,
            ease: _ease
        });
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}