import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';

let _root = this;

export default class SliderGallery {
  constructor(root) {
    _root = this;
    this.initSliderGallery();
  }

  initSliderGallery() {
    const interleaveOffset = 0.5;

    document.querySelectorAll('.slider-gallery-swiper').forEach(_swiperContainer => {
      const swiper = new Swiper(_swiperContainer, {
        
        loop: false,
        speed: 300,
        grabCursor: true,
        //watchSlidesProgress: true,
        init: false,
        preloadImages: false,
        parallax: true,
        modules: [Navigation],
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        lazy: true,
        navigation: {
          nextEl: _swiperContainer.parentElement.querySelector(".swiper-button-next"),
          prevEl: _swiperContainer.parentElement.querySelector(".swiper-button-prev"),
        },

        on: {
        progress: function() {
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            let slideProgress = swiper.slides[i].progress;
            let innerOffset = swiper.width * interleaveOffset;
            let innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector('.swiper-slide-picture').style.transform =
              'translate3d(' + innerTranslate + 'px, 0, 0)';
          }
        },
        touchStart: function() {
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = '';
          }
        },
        setTransition: function(speed) {
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = speed + 'ms';
            swiper.slides[i].querySelector('.swiper-slide-picture').style.transition = speed + 'ms';
          }
        }
      }
      });
      if ('undefined' != typeof _swiperContainer && null != _swiperContainer) {
        swiper.init();
      }
    });
  
  }
}
